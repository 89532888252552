<template>
    <VRow no-gutters>
        <VCol cols="2">
            <VList>
                <VListItem v-for="client in clients" :value="client" key="update" @click="selectClient(client)" prepend-avatar="https://cdn.vuetifyjs.com/images/parallax/material.jpg">
                    <VListItemTitle>{{ `${client.firstName} ${client.lastName}` }}</VListItemTitle>
                </VListItem>
            </VList>
        </VCol>
        <VCol cols="10">
            <MessageLog :selectedClient="selectedClient" :isTrainer="isTrainer"></MessageLog>
            <VSheet min-width="100%" min-height="1000px">
                <VTextField v-model="messageText"></VTextField>
                <VBtn @click="sendMessage">Send</VBtn>
            </VSheet>
        </VCol>
    </VRow>
</template>

<script setup>
import { ref, watch, computed, reactive } from 'vue';
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';
import { timestamp } from '@/firebase/config';
import MessageLog from '@/components/MessageLog'

const { user } = getUser();
const { error, addDocument, updateDocument, getDocuments } = useCollection();

const userObject = ref(null);
var {getError} = getDocuments("users", user.value.uid, userObject);
const isTrainer = ref(false);

const clients = ref(null);
watch(userObject, () => {
    if(userObject.value) {
        if (userObject.value[0].isTrainer) {
            var {getError} = getDocuments("clients", user.value.uid, clients);
            isTrainer.value = true;
        } else {
            var {getError} = getDocuments("trainers", user.value.uid, clients);
        }
    }
})

const messageText = ref("");
const selectedClient = ref(null);

const selectClient = (client) => {
    selectedClient.value = client;
}

const sendMessage = async () => {
    let message = {
        createdAt: timestamp,
        from: user.value.uid,
        content: messageText.value
    }
    if (isTrainer.value) {
        await addDocument(`clients/${selectedClient.value.id}/chatrooms`, user.value.uid, message);
    } else {
        await addDocument(`clients/${user.value.uid}/chatrooms`, selectedClient.value.id, message);
    }

    messageText.value = "";
};
</script>

<style>

</style>