<template>
    <VDialog v-model="addEvent" max-width="600">
        <VSheet class="px-4 py-4 mx-2 my-2" elevation="1">
            <h1>Schedule a workout</h1>
            <h2>{{ selectedDate  }}</h2>
            <VSelect v-model="selectedWorkout" label="Workout List" :items="workouts" item-title="workoutName" return-object=""></VSelect>
            <VBtn text="Add" @click="scheduleWorkout"/>

        </VSheet>
    </VDialog>
    <v-container>
        <VSelect v-if="clients" v-model="selectedClient" label="Client" :items="clients" item-title="firstName" return-object="">
            <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :subtitle="item.raw.lastName"></v-list-item>
            </template>
        </VSelect>
        <Qalendar v-if="selectedClient"
        :key="count"
        :events="events"
        :config="config"
        @date-was-clicked="clickedDate" />
    </v-container>
</template>
<script setup>
import { ref, watch } from 'vue'
import { Qalendar } from 'qalendar';
import "qalendar/dist/style.css";
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';
import { v4 as uuidv4 } from "uuid";
import { timestamp } from '@/firebase/config';

const { error, addDocument, updateDocument, getDocuments } = useCollection();
const { user } = getUser();
const workouts = ref(null);
var { getError } = getDocuments('workouts', user.value.uid, workouts);
const selectedWorkout = ref();
const selectedDate = ref("");
const count = ref(0);
const addEvent = ref(false);
const events = ref([]);
const clients = ref(null);
const selectedClient = ref(null);
var {getError} = getDocuments("clients", user.value.uid, clients);

const config = ref({
      week: {
        // Takes the value 'sunday' or 'monday'
        // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
        startsOn: 'sunday',
        // Takes the values 5 or 7.
        nDays: 7,
      },
      // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
      // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
      defaultMode: 'month',
    });

watch(selectedClient, () => {
    var {getError} = getDocuments(`clients/${selectedClient.value.id}/events`, user.value.uid, events);
})

const scheduleWorkout = async () => {
    if (selectedWorkout.value) {
        let event = {
            title: selectedWorkout.value.title,
            time: {start: selectedDate.value, end: selectedDate.value},
            color: "yellow",
            isEditable: true,
            id: uuidv4(),
            description : "",
            createdAt: timestamp
        }
        await addDocument(`clients/${selectedClient.value.id}/events`, user.value.uid, event);

        events.value.push(event);
    }
    selectedWorkout.value = null;
    addEvent.value = false;
    count.value++;
}

const clickedDate = (date) => {
    selectedDate.value = date;
    addEvent.value = true;
}
</script>
