import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import Exercise from '../views/Exercise.vue';
import TrainerDashboard from '../views/TrainerDashboard';
import Workout from '../views/Workout';
import Clients from '../views/Clients';
import ClientSignup from '../views/ClientSignup';
import { onAuthStateChanged } from "firebase/auth";
import Messages from '../views/Messages';
import Calendar from '@/views/Calendar.vue';

// route guard
import { auth } from '../firebase/config';

const getUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe()
        resolve(user)
      },
      reject
    )
  })
}

const requireAuth = async (to, from, next) => {
  let user = await getUser();
  if (!user) {
    next({name : 'Login'});
  } else {
    next();
  }
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/clientSignup',
    name: 'ClientSignup',
    component: ClientSignup
  },
  {
    path: '/exercise/upload',
    name: 'Exercise',
    component: Exercise,
    beforeEnter: requireAuth
  },
  {
    path: '/trainerDash',
    name: 'TrainerDashboard',
    component: TrainerDashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/workouts',
    name: 'Workout',
    component: Workout,
    beforeEnter: requireAuth
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    beforeEnter: requireAuth
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    beforeEnter: requireAuth
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    beforeEnter: requireAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
