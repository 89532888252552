<template>
    <Modal :exercises=exercises :selectedWorkout="editWorkout" @reset="reset"></Modal>
    <VSheet v-if="workouts">
        <VExpansionPanels>
            <VExpansionPanel v-for="(item, i) in workouts" :key="i">
                <VExpansionPanelTitle>
                    <VImg class="mx-2" maxWidth="50" aspectRatio="1" cover
                        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"></VImg>
                    <h2 v-text="item.workoutName" @click="console.log(item)"></h2>
                    <p class="px-2">
                        {{ `Duration: ${item.duration} mins | ${uniqueExercises.length} exercises` }}
                    </p>
                </VExpansionPanelTitle>
                <VExpansionPanelText class="text-left">
                    <div class="my-2">
                        <VBtn text="Edit" @click="handleEdit(item)"/>
                        <VBtn color="red" text="Delete" @click="handleDelete(item)"/>
                    </div>
                    <h3>Muscle Groups</h3>
                    <VChip v-for="muscle in uniqueMuscleGroups[i]">{{muscle}}</VChip>
                    <h3>Equipment</h3>
                    <VChip v-for="equipment in uniqueEquipment[i]">{{equipment}}</VChip>
                    <hr>
                    <div v-for="(exercise, index) in item.workout">
                        <VSheet v-if="Object.hasOwn(exercise, 'laps')">
                            <h2>Circuit with {{ exercise.laps }} laps</h2>
                            <div v-for="nestedExercise in exercise.workout" class="vertical-align-center">
                                <VIcon v-if="nestedExercise.exercise.title === 'Rest'" class="mr-1" icon="mdi-clock-time-eight-outline" />
                                <img v-else class="mr-1" width="50" :src="nestedExercise.exercise.thumbnailUrl" @click="openExerciseDialog(nestedExercise.exercise)" />
                                <div class="d-inline-flex">
                                    <p>
                                        {{ nestedExercise.exercise.title }} x
                                        {{ nestedExercise.length }}
                                        {{ nestedExercise.type == "Reps" ? "reps" : "seconds" }}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <VIcon class="mr-1" icon="mdi-autorenew" />
                                <p class="d-inline-block">Repeat new set</p>
                            </div>
                            <hr>
                        </VSheet>
                        <div v-else>
                            <VIcon v-if="exercise.title === 'Rest'" class="mr-1" icon="mdi-clock-time-eight-outline" />
                            <img v-else class="mr-1" width="50" :src="exercise.exercise.thumbnailUrl" @click="openExerciseDialog(exercise.exercise)" />
                            <div class="d-inline-flex">
                                <p>
                                    {{ exercise.exercise.title }} x
                                    {{ exercise.length }}
                                    {{ exercise.type == "Reps" ? "reps" : "seconds" }}
                                </p>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <VList class="text-left">
                        <h3>Exercises</h3>
                        <VListItem v-for="exercise in uniqueExercises[i]">
                            <img class="mr-6" width="100" :src="exercise.thumbnailUrl" @click="openExerciseDialog(exercise)"/>
                            <div class="d-inline-block vertical-align-top">
                                <h2>{{ exercise.title }}</h2>
                                <ol>
                                    <li v-for="instrunction in exercise.instructions">{{ instrunction }}</li>
                                </ol>
                            </div>
                        </VListItem>
                    </VList>
                </VExpansionPanelText>
            </VExpansionPanel>
        </VExpansionPanels>
    </VSheet>
    <v-dialog v-model="dialog">
        <v-sheet class="py-4 px-4 mx-auto" width="400">
            <h2 class="my-2">Delete Workout</h2>
            <p class="my-2">Are you sure you want to delete this workout?</p>
            <v-btn text="Cancel" @click="dialog = false;"></v-btn>
            <v-btn color="red" text="Delete" @click="confirmedDelete"></v-btn>
        </v-sheet>
    </v-dialog>
    <VDialog v-model="exerciseDialog" max-width="800">
        <VSheet class="py-2 px-2" elevation="1">
            <VRow>
                <VCol cols="4">
                    <video width="200" :src="selectedExercise.url" autoplay loop muted/>
                </VCol>
                <VCol class="text-left">
                    <h2>{{ selectedExercise.title  }}</h2>
                    <h3>Equipment:</h3>
                    <hr>
                    <h3>Targeted Muscles:</h3>
                    <hr>
                    <h3>Instructions:</h3>
                    <ol>
                        <li v-for="instrunction in selectedExercise.instructions">{{ instrunction }}</li>
                    </ol>
                </VCol>
            </VRow>
        </VSheet>
    </VDialog>
</template>

<script setup>
import Modal from '@/components/WorkoutModal';
import { ref, watch } from 'vue';
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';
import useDocument from '@/composables/useDocument';
const { user } = getUser();
const { deleteDocument } = useDocument();
const { error, addDocument, updateDocument, getDocuments } = useCollection();
const exercisesDocuments = ref(null);
var { getError } = getDocuments('exercises', user.value.uid, exercisesDocuments);
const exercises = ref([]);
const workouts = ref(null);
var { getError } = getDocuments('workouts', user.value.uid, workouts);
const dialog = ref(false);
const selectedWorkout = ref();
const editWorkout = ref();
const exerciseDialog = ref(false);

watch(exercisesDocuments, () => {
    const rest = {
        id: "1",
        title: "Rest",
        userId: "",
        userName: "",
        url: "",
        filePath: "",
        createdAt: 0,
    }
    exercises.value = [rest].concat(exercisesDocuments.value);
});

const uniqueExercises = ref([]);
const uniqueMuscleGroups = ref([]);
const uniqueEquipment = ref([]);
watch(workouts, () => {
    let exerciseArray = [];
    let muscleArray = [];
    let equipmentArray = [];
    workouts.value.forEach((workout, index) => {
        workout['workout'].forEach((exercise) => {
            if (Object.hasOwn(exercise, "laps")) {
                exercise['workout'].forEach((nestedExercise) => {
                    if (nestedExercise['exercise'].title !== "Rest") {
                        exerciseArray.push(nestedExercise['exercise']);
                        nestedExercise['exercise']['targetedMuscles'].forEach((muscle) => {
                            muscleArray.push(muscle);
                        });
                        nestedExercise['exercise']['requiredEquipment'].forEach((equipment) => {
                            equipmentArray.push(equipment);
                        });
                    }
                })
            } else {
                if (exercise['exercise'].title !== "Rest") {
                    exerciseArray.push(exercise['exercise']);
                    exercise['exercise']['targetedMuscles'].forEach((muscle) => {
                        muscleArray.push(muscle);
                    });
                    exercise['exercise']['requiredEquipment'].forEach((equipment) => {
                            equipmentArray.push(equipment);
                    });
                }
            }
        });
        exerciseArray = exerciseArray.filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)))
        uniqueExercises.value.push(exerciseArray);

        muscleArray = muscleArray.filter((value, index, self) => index === self.findIndex((t) => (t === value)));
        uniqueMuscleGroups.value.push(muscleArray);

        equipmentArray = equipmentArray.filter((value, index, self) => index === self.findIndex((t) => (t === value)));
        uniqueEquipment.value.push(equipmentArray);

        exerciseArray = [];
        muscleArray = [];
        equipmentArray = [];
    });
});

const reset = () => {
    selectedWorkout.value = null;
    editWorkout.value = null;
};

const handleEdit = (workout) => {
    editWorkout.value = workout;
};

const handleDelete = (item) => {
    selectedWorkout.value = item;
    dialog.value = true;
};

const confirmedDelete = async () => {
    await deleteDocument('workouts', user.value.uid, selectedWorkout.value.id);
    dialog.value = false;
};

const selectedExercise = ref();
const openExerciseDialog = (exercise) => {
    exerciseDialog.value = true;
    selectedExercise.value = exercise;
}

</script>

<style>
.vertical-align-top {
    vertical-align: top
}
</style>