<template>
    <v-layout class="justify-center">
        <VSheet class="px-4 py-4 mx-2 rounded" elevation="1" width="20%">
            <form>
                <h3>Sign up</h3>
                <input type="text" placeholder="Username" v-model="username">
                <input type="text" placeholder="First Name" v-model="firstName">
                <input type="text" placeholder="Last Name" v-model="lastName">
                <input type="email" placeholder="Email" v-model="email">
                <input type="password" placeholder="Password" v-model="password">
                <div v-if="error"  >{{ error }}</div>
                <VBtn v-if="!isPending" @click="handleSubmit">Sign up</VBtn>
                <VBtn v-if="isPending">Loading</VBtn>
            </form>
        </VSheet>
    </v-layout>
</template>

<script setup>
import useSignup from '@/composables/useSignup';
import router from '@/router';
import {ref} from 'vue';

const {error, signup, isPending} = useSignup();

const username = ref('');
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const password = ref('');

const handleSubmit = async () => {
    const res = await signup(email.value, password.value, username.value, firstName.value, lastName.value, true);
    if (!error.value) {
        router.push({ name: 'TrainerDashboard' });
    }
};

</script>

<style>
</style>