import { ref } from 'vue';
import { storage } from "@/firebase/config";
import getUser from './getUser';
import { getDownloadURL, uploadBytes, ref as sRef, deleteObject} from "firebase/storage";

const { user } = getUser();

const useStorage = () => {
    const error = ref(null);
    const url = ref(null);
    const filePath = ref(null);

    const uploadVideo = async (file) => {
        filePath.value = `videos/${user.value.uid}/${file.name}`;
        const storageRef = sRef(storage, filePath.value);

        try {
            const res = await uploadBytes(storageRef, file);
            url.value = await getDownloadURL(storageRef);
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    }

    const uploadImage = async (file) => {
        filePath.value = `images/${user.value.uid}/${file.name}`;
        const storageRef = sRef(storage, filePath.value);

        try {
            const res = await uploadBytes(storageRef, file);
            url.value = await getDownloadURL(storageRef);
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    }

    const deleteStorage = async (path) => {
        const storageRef = sRef(storage, path);

        try {
            await deleteObject(storageRef);
        } catch(err) {
            console.log(err.message);
            error.value = err.message;
        }
    };
    return { url, filePath, error, uploadVideo, uploadImage, deleteStorage};
}

export default useStorage;