import { initializeApp } from "firebase/app";
import { initializeAuth, getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator} from "firebase/storage";
import { getFirestore, serverTimestamp, connectFirestoreEmulator} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

let firebaseConfig;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
if (process.env.VUE_APP_NODE_ENV == "local") {
  console.log("Local Env");
  firebaseConfig = {
    apiKey: "AIzaSyBuxCB3016CY1Uc-J1wUR7ktkYTfVoYO74",
    authDomain: "perspiration-app.firebaseapp.com",
    projectId: "perspiration-app",
    storageBucket: "perspiration-app.appspot.com",
    messagingSenderId: "333713698471",
    appId: "1:333713698471:web:50ad18f1652753afc57582",
    measurementId: "G-GPRZP5EQMZ"
  };
  // firebaseConfig = {
  //   apiKey: "AIzaSyDWwjPlTW8BzX48dqg9JfwI9H3pmGIGPYA",
  //   authDomain: "twms-dev.firebaseapp.com",
  //   projectId: "twms-dev",
  //   storageBucket: "twms-dev.appspot.com",
  //   messagingSenderId: "345513750563",
  //   appId: "1:345513750563:web:f27551aaeba7c20e6d0196",
  //   measurementId: "G-S22WFW10D5"
  // };
} else {
  // DEV ENV
  // firebaseConfig = {
  //   apiKey: "AIzaSyDWwjPlTW8BzX48dqg9JfwI9H3pmGIGPYA",
  //   authDomain: "twms-dev.firebaseapp.com",
  //   projectId: "twms-dev",
  //   storageBucket: "twms-dev.appspot.com",
  //   messagingSenderId: "345513750563",
  //   appId: "1:345513750563:web:f27551aaeba7c20e6d0196",
  //   measurementId: "G-S22WFW10D5"
  // };
  // PROD
  firebaseConfig = {
    apiKey: "AIzaSyD0QWw3quuPw5UOb__ldzjQvXnTPIT_RDU",
    authDomain: "perspiration-app.firebaseapp.com",
    projectId: "perspiration-app",
    storageBucket: "perspiration-app.appspot.com",
    messagingSenderId: "333713698471",
    appId: "1:333713698471:web:c1febda7c4be84a6c57582",
    measurementId: "G-EGK0J28TNL"
  };
}

const app = initializeApp(firebaseConfig);

if (process.env.VUE_APP_NODE_ENV != "local") {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcJUg0qAAAAALLr4_h8kXVnEpQk7yhVnNa4kjWj'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
}

const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);
const timestamp = serverTimestamp();
const functions = getFunctions(app);

if (process.env.VUE_APP_NODE_ENV == "local") {
  connectFirestoreEmulator(db, '127.0.0.1', 8081);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFunctionsEmulator(functions, "127.0.0.1", 5005);
}

export {auth, storage, db, timestamp, functions};