<template>
    <VInfiniteScroll @click="console.log(props.selectedClient); console.log(props.isTrainer)" @load="load" empty-text="" >
        <div v-if="messages">
            <div v-for="message in messages">
                <div v-if="message.from === user.uid" class="text-right">
                    <p class="mx-2 px-2 send">{{ message.content }}</p>
                </div>
                <div v-else class="text-left">
                    <p class="mx-2 px-2 recv">{{ message.content }}</p>
                </div>

            </div>
        </div>
    </VInfiniteScroll>
</template>

<script setup>
import { ref, watch } from 'vue';
import getUser from '@/composables/getUser';
import useCollection from '@/composables/useCollection';

const props = defineProps({
    selectedClient: Object,
    isTrainer: Boolean
});

const { user } = getUser();
const { error, addDocument, updateDocument, getDocuments } = useCollection();
const messages = ref(null);


watch(props, () => {
  if (props.selectedClient) {
    if (props.isTrainer) {
        var {getError} = getDocuments(`clients/${props.selectedClient.id}/chatrooms`, user.value.uid, messages);
    } else {
        var {getError} = getDocuments(`clients/${user.value.uid}/chatrooms`, props.selectedClient.id, messages);
    }
  }
})

const load = (({done}) => {
    done('empty');
});
</script>

<style>
.recv {
    background-color: rgb(187, 187, 243);
    text-align: left;
    display: inline-block;
    border-radius: 10px;
}

.send {
    background-color: greenyellow;
    text-align: right;
    display: inline-block;
    border-radius: 10px;
}

</style>