<template>
    <v-layout class="justify-center">
        <VSheet class="px-4 py-4 mx-2 rounded" elevation="1" width="20%" >
            <form>
                <h3>Sign up</h3>
                <input type="password" placeholder="Password" v-model="password">
                <div v-if="error">{{ error }}</div>
                <VBtn v-if="!isPending" @click="handleSubmit">Sign up</VBtn>
                <VBtn v-if="isPending">Loading</VBtn>
            </form>
        </VSheet>
    </v-layout>
</template>

<script setup>
import { httpsCallable } from 'firebase/functions';
import { ref } from 'vue';
import { functions } from '@/firebase/config';
import router from '@/router';
import { useRoute } from "vue-router";

const route = useRoute();
const registerClient = httpsCallable(functions, "registerClient");

const password = ref('');

const handleSubmit = async () => {
    registerClient({ uid: route.query.uid, cid: route.query.cid, password: password.value }).then((result) => {
        if (result.data === 200) {
            router.push({ name: 'Login' });
        }
    })
};

</script>

<style>
</style>