import { ref, watchEffect } from 'vue';
import { db } from '../firebase/config';
import { addDoc, updateDoc, doc, collection, query, orderBy, onSnapshot } from 'firebase/firestore';

const useCollection = () => {

  const error = ref(null)

  // add a new document
  const addDocument = async (collectionName, userId, data) => {
    error.value = null

    try {
      await addDoc(collection(db, 'app', userId, collectionName), data);
    }
    catch(err) {
      console.log(err.message)
      error.value = 'could not send the message'
    }
  }

  const updateDocument = async (collectionName, userId, data) => {
    error.value = null;

    try {
      const docRef = await doc(db, 'app', userId, collectionName, data.id);
      await updateDoc(docRef, data);
    } catch (err) {
      console.log(err.message);
    }
  }

  const getDocuments = (collectionName, userId, refDocuments) => {
    const error = ref(null)

    // register the firestore collection reference
    const q = query(collection(db, 'app', userId, collectionName), orderBy("createdAt"));

    const unsub = onSnapshot(q, (snap) => {
      let results = []
      snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        doc.data().createdAt && results.push({...doc.data(), id: doc.id})
      });

      // update values
      refDocuments.value = results
      error.value = null
    }, err => {
      console.log(err.message)
      refDocuments.value = null
      error.value = 'could not fetch the data'
    })

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });

    return { error };
  }

  return { error, addDocument, updateDocument, getDocuments }

}

export default useCollection