<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="dialog"
      @after-leave="$emit('reset')"
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn v-bind="activatorProps">
          Create New Program
        </v-btn>
      </template>

      <v-sheet class="py-4 px-4">
        <h1 v-if="selectedWorkout">Edit Program</h1>
        <h1 v-else>Create a new Program</h1>
        <div class="modal-body">
          <VRow>
            <VCol>
              <VTextField label="Workout Name" v-model="workoutName"></VTextField>
              <VTextField label="Estimated Duration" type="number" v-model="estimatedDuration"></VTextField>
              <VTextField label="Laps" type="number" v-model="laps"></VTextField>
            </VCol>
            <VCol>
              <VSelect v-model="selectedExercise" label="Exercises" :items="exercises" return-object=""></VSelect>
              <VSelect v-model="selectedExerciseType" :items="exerciseTypes" return-object="" :disabled="typeDisabled"></VSelect>
              <VTextField :label="generateLabel()" type="number" v-model="workoutLength"></VTextField>
              <v-btn @click="addExercise">Add Exercise</v-btn>
            </VCol>
            <VCol>
              <v-btn @click="makeCircuit">Group as circuit</v-btn>
              <VSheet class="py-4 px-4 my-2 mx-2" elevation="1" v-for="(set, index) in workout">
                  <div v-if="Object.hasOwn(set, 'laps')">
                    <VRow class="my-2">
                      <h2>Circuit {{ index + 1}}</h2>
                      <v-btn @click="moveUp(index)"><v-icon icon="mdi-arrow-up-bold"></v-icon></v-btn>
                      <v-btn @click="moveDown(index)"><v-icon icon="mdi-arrow-down-bold"></v-icon></v-btn>
                      <v-btn @click="remove(index)">X</v-btn>
                    </VRow>
                    <VTextField label="Laps" type="number" v-model="set.laps"></VTextField>
                    <div v-for="workout in set.workout">
                      <div>{{ workout.exercise.title }} x {{ workout.length }} {{ workout.type == "Reps" ? "reps" : "seconds" }}</div>
                    </div>
                  </div>
                  <div v-else>
                    <VRow>
                      <VCheckbox v-model="set.selected"></VCheckbox>
                      <div>{{ set.exercise.title }} x {{ set.length }} {{ set.type == "Reps" ? "reps" : "seconds" }}</div>
                    </VRow>
                    <VRow>
                      <v-btn @click="moveUp(index)"><v-icon icon="mdi-arrow-up-bold"></v-icon></v-btn>
                      <v-btn @click="moveDown(index)"><v-icon icon="mdi-arrow-down-bold"></v-icon></v-btn>
                      <v-btn @click="remove(index)">X</v-btn>
                    </VRow>
                  </div>
              </VSheet>
            </VCol>
          </VRow>
        </div>
          <v-btn @click="submitWorkout">Submit</v-btn>
          <v-btn @click="cancelWorkout">Cancel</v-btn>
      </v-sheet>
    </v-dialog>
  </div>
 </template>

<script setup>
import { ref, watch } from 'vue';
import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser';
import { timestamp } from '@/firebase/config';

const props = defineProps({
  selectedWorkout: Object,
  exercises: Array
})

const emit = defineEmits(['close', 'reset']);

const { user } = getUser();
const { error, addDocument, updateDocument } = useCollection();

const workoutLength = ref(0);
const workout = ref([]);
const circuits = ref([]);
const isPending = ref(false);
const workoutName = ref("");
const dialog = ref(false);
const selectedExercise = ref();
const exerciseTypes = ["Reps", "Timed"];
const selectedExerciseType = ref("Reps");
let typeDisabled = false;
const estimatedDuration = ref(0);
const laps = ref(0);

watch(dialog, (isOpen) => {
  if (isOpen && props.selectedWorkout) {
    workoutName.value = props.selectedWorkout.workoutName;
    workout.value = structuredClone(props.selectedWorkout.workout);
  } else {
    workoutName.value = "";
    workout.value = [];
  }
})

watch(props, () => {
  if (props.selectedWorkout) {
    dialog.value = true;
  }
})

watch(selectedExercise, () => {
  if (selectedExercise.value && selectedExercise.value.id == "1" && selectedExercise.value.title == "Rest") {
    selectedExerciseType.value = "Timed";
    typeDisabled = true;
  } else {
    typeDisabled = false;
  }
})

const makeCircuit = () => {
  let deletedWorkouts = [];
  workout.value.forEach((workout, index) => {
    if (workout.selected == true) {
      circuits.value.push(workout);
      deletedWorkouts.push(index);
    }
  });

  deletedWorkouts.reverse().forEach(index => remove(index));
  let circuitObject = {laps: 0, workout: circuits.value};
  workout.value.push(circuitObject);
  circuits.value = [];
}

const generateLabel = () => {
  if (selectedExerciseType.value == "Reps") {
    return "# of reps";
  } else if (selectedExerciseType.value == "Timed") {
    return "Time in seconds";
  }
}

const addExercise = () => {
    if (!selectedExercise.value) {
      return
    }
    let set = {selected: false, type: selectedExerciseType.value, length: workoutLength.value, exercise: selectedExercise.value };
    workout.value.push(set);
}

const moveUp = (index) => {
  if (index > 0) {
    let temp = workout.value[index];
    workout.value[index] = workout.value[index - 1]
    workout.value[index - 1] = temp;
  }
};

const moveDown = (index) => {
  if (index < workout.value.length - 1) {
    let temp = workout.value[index];
    workout.value[index] = workout.value[index + 1]
    workout.value[index + 1] = temp;
  }
};

const remove = (index) => {
  workout.value.splice(index, 1);
};

const cancelWorkout = () => {
  workoutLength.value = null;
  selectedExercise.value = null;
  workout.value = [];
  dialog.value = false;
  emit('close');
};

const submitWorkout = async () => {
  isPending.value = true;
  if (props.selectedWorkout) {
    let editedWorkout = props.selectedWorkout;
    editedWorkout.workoutName = workoutName.value;
    editedWorkout.duration = estimatedDuration.value;
    editedWorkout.laps = laps.value;
    editedWorkout.workout = workout.value;
    editedWorkout.createdAt = timestamp;
    updateDocument("workouts", user.value.uid, editedWorkout)
    emit('reset');
  } else {
    await addDocument("workouts", user.value.uid, {
      workoutName: workoutName.value,
      duration: estimatedDuration.value,
      laps: laps.value,
      workout: workout.value,
      createdAt: timestamp
    })
  }

  workoutLength.value = null;
  selectedExercise.value = null;
  workout.value = [];
  isPending.value = false;
  dialog.value = false;
  emit('close');
}

</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>