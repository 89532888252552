<template>
    <VDialog v-model="addExercise" max-width="600">
        <v-sheet class="px-4 py-4 mx-2 my-2" elevation="1">
            <form>
                <h4>Add New Exercise</h4>
                <input type="text" required placeholder="Exercise Name" v-model="newExercise.title">
                <h5>Targeted Muscles</h5>
                <VSelect v-model="newExercise.targetedMuscles" :items="muscles" multiple>
                    <template v-slot:selection="{item, index}">
                        <VChip>
                            <span>{{ item.title }}</span>
                        </VChip>
                    </template>
                </VSelect>
                <h5>Required Equipment</h5>
                <VSelect v-model="newExercise.requiredEquipment" :items="equipment" multiple>
                    <template v-slot:selection="{item, index}">
                        <VChip>
                            <span>{{ item.title }}</span>
                        </VChip>
                    </template>
                </VSelect>
                <h5>Instructions</h5>
                <VTextField v-model="newExercise.instructions[index]" v-for="(instruction, index) in newExercise.instructions"></VTextField>
                <VBtn class="my-2" @click="newExercise.instructions.push('')">Add instruction</VBtn>
                <div>
                    <label v-if="!previewUrl">Upload video clip of exercise</label>
                </div>
                <input type="file" @change="handleChange">
                <div class="error">{{ fileError }}</div>

                <v-row>
                    <v-col>
                        <video ref="videoElement" width="300" :src="videoUrl ? videoUrl : newExercise.url" @loadedmetadata="loaded"
                            @timeupdate="timeUpdate" />
                        <div>
                            <vue-slider @drag-end="sliderChange" :key="componentKey" v-model="range"
                                :enable-cross="false" :min="0" :max="max" :interval="interval"
                                :lazy="true"></vue-slider>
                        </div>
                        <div>
                            <v-btn @click="play">Play</v-btn>
                            <v-btn @click="pause">Pause</v-btn>
                            <v-btn v-if="!isClipping" @click="clip">Clip</v-btn>
                            <v-btn v-else disabled="">Clipping...</v-btn>
                            <VBtn v-if="!isGenThumbnail" @click="genThumbnail">Generate Thumbnail</VBtn>
                            <VBtn v-else disabled="">Generating...</VBtn>
                        </div>
                    </v-col>
                    <v-col>
                        <video width="300" :src="previewUrl ? previewUrl : newExercise.url" autoplay loop muted />
                        <img width="300" :src="thumbnailUrl ? thumbnailUrl : newExercise.thumbnailUrl">
                    </v-col>
                </v-row>

                <div class="error"></div>
                <v-btn v-if="!isPending" @click="handleSubmit">Upload</v-btn>
                <v-btn v-else disabled>Uploading...</v-btn>
            </form>
        </v-sheet>
    </VDialog>
    <v-btn class="mb-2" @click="addExercise = true">Add New Exercise</v-btn>
    <v-infinite-scroll v-if="exercises" @load="load" height="800">
        <VContainer>
            <VRow>
                <VCol v-for="(item, index) in items" :key="item" cols="3">
                    <VCard width="200" @click="openExerciseDialog(item)">
                        <img width="200" :src="item.thumbnailUrl">
                        <VCardTitle>{{ item.title }}</VCardTitle>
                    </VCard>
                </VCol>
            </VRow>
        </VContainer>
    </v-infinite-scroll>
    <VDialog v-model="deleteDialog">
        <v-sheet class="py-4 px-4 mx-auto" width="400">
            <h2 class="my-2">Delete Exercise</h2>
            <p class="my-2">Are you sure you want to delete this exercise?</p>
            <v-btn text="Cancel" @click="deleteDialog = false;"></v-btn>
            <v-btn color="red" text="Delete" @click="confirmedDelete"></v-btn>
        </v-sheet>
    </VDialog>
    <VDialog v-model="exerciseDialog" max-width="800">
        <VSheet class="py-2 px-2" elevation="1">
            <VRow>
                <VCol cols="4">
                    <video width="200" :src="selectedExercise.url" autoplay loop muted />
                </VCol>
                <VCol class="text-left">
                    <h2>{{ selectedExercise.title }}</h2>
                    <h3>Equipment:</h3>
                    <VChip v-for="equipment in selectedExercise.requiredEquipment">
                        {{ equipment }}
                    </VChip>
                    <h3>Targeted Muscles:</h3>
                    <VChip v-for="muscle in selectedExercise.targetedMuscles">
                        {{ muscle }}
                    </VChip>
                    <hr>
                    <h3>Instructions:</h3>
                    <ol>
                        <li v-for="instruction in selectedExercise.instructions">{{ instruction }}</li>
                    </ol>
                    <VBtn @click="handleEdit(selectedExercise)">Edit</VBtn>
                    <v-btn base-color="red" @click="handleDelete(selectedExercise, index)">Delete</v-btn>
                </VCol>
            </VRow>
        </VSheet>
    </VDialog>
</template>

<script setup>
import { ref } from 'vue';
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection';
import getUser from '@/composables/getUser';
import { timestamp } from '@/firebase/config';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util"
import { v4 as uuidv4 } from "uuid";
import useDocument from '@/composables/useDocument';

const types = ['video/mp4'];

const { filePath, url, uploadVideo, uploadImage, deleteStorage } = useStorage();

const { user } = getUser();
const { error, addDocument, getDocuments, updateDocument } = useCollection();
const exercises = ref(null);
const { getError } = getDocuments('exercises', user.value.uid, exercises);
const { deleteDocument } = useDocument();


const title = ref('');
const file = ref(null);
const thumbnailFile = ref(null);
const previewUrl = ref(null);
const thumbnailUrl = ref(null);
const fileError = ref(null);
const isPending = ref(false);
const isClipping = ref(false);
const isGenThumbnail = ref(false);
const imgInput = ref(null);
const videoUrl = ref(null);
const videoElement = ref(null);
const range = ref([0, 0, 0]);
const max = ref(50);
const componentKey = ref(0);
const interval = 0.000000001;
const ffmpeg = new FFmpeg();
const deleteDialog = ref(false);
const exerciseDialog = ref(false);
const addExercise = ref(false);
const instructions = ref([""]);
const newExercise = ref({title: "", targetedMuscles: [], requiredEquipment: [], instructions: []});

const muscles = ["Abs", "Lower Back", "Bicep", "Calves", "Chest", "Forearms", "Glutes", "Hamstrings", "Lats", "Neck", "Obliques", "Quads", "Shoulder", "Traps", "Triceps"];
const equipment = ["Bands", "Barbell", "Battle ropes", "Bench", "Body weight", "BOSU", "Box", "Cable", "Dumbbell", "EZ bar", "Foam roller", "Jump rope", "Kettlebell", "Sled", "Pull up bar"];

const sliderChange = () => {
    if (videoElement.value) {
        videoElement.value.currentTime = range.value[1];
    }
}

const handleSubmit = async () => {
    if (editExercise) {
        isPending.value = true;
        if (file.value) {
            await uploadVideo(file.value);
            await deleteStorage(deleteFiles.filePath);
            newExercise.value.filePath = filePath.value;
            newExercise.value.url = url.value;
        }

        if (thumbnailFile.value) {
            await uploadImage(thumbnailFile.value);
            await deleteStorage(deleteFiles.thumbnailPath);
            newExercise.value.thumbnailPath = filePath.value;
            newExercise.value.thumbnailUrl = url.value;
        }

        updateDocument('exercises', user.value.uid, newExercise.value);

    } else if (file.value) {
        isPending.value = true;
        await uploadVideo(file.value);
        let videoFilePath = filePath.value;
        let downloadVideoUrl = url.value;
        await uploadImage(thumbnailFile.value);
        let thumbnailFilePath = filePath.value;
        let downloadThumbnailUrl = url.value;
        await addDocument(
            'exercises', user.value.uid,
            {
            title: newExercise.value.title,
            userId: user.value.uid,
            userName: user.value.displayName,
            url: downloadVideoUrl,
            thumbnailUrl: downloadThumbnailUrl,
            filePath: videoFilePath,
            thumbnailPath: thumbnailFilePath,
            targetedMuscles: newExercise.value.targetedMuscles,
            requiredEquipment: newExercise.value.requiredEquipment,
            instructions: instructions.value,
            createdAt: timestamp
        })

        // if (!error.value) {
        //     console.log("Exercise added");
        // }
    }
    isPending.value = false;
    file.value = null;
    thumbnailFile.value = null;
    title.value = "";
    previewUrl.value = null;
    thumbnailUrl.value = null;
    imgInput.value = null;
    videoUrl.value = null;
    editExercise = false;
    addExercise.value = false;
    newExercise.value = {title: "", targetedMuscles: [], requiredEquipment: [], instructions: []};
};

const play = () => {
    if (videoElement.value) {
        if (videoElement.value.currentTime >= range.value[2]) {
            videoElement.value.currentTime = range.value[0];
        }
        videoElement.value.play();
    }
};

const forceRender = () => {
    componentKey.value += 1;
}

const timeUpdate = () => {
    if (videoElement.value) {
        var value = range.value;
        value[1] = videoElement.value.currentTime;
        if (value[1] >= range.value[2]) {
            value[1] = value[2];
            videoElement.value.pause();
        } else {
            range.value = value;
        }
        forceRender();
    }
}

const pause = () => {
    if (videoElement.value) {
        videoElement.value.pause();
    }
}

const loaded = () => {
    if (videoElement.value) {
        max.value = videoElement.value.duration;
        range.value[2] = videoElement.value.duration;
        forceRender();
    }
}

const clip = async () => {
    isClipping.value = true;
    let duration = (range.value[2] - range.value[0]).toString();
    let start = range.value[0].toString();

    await ffmpeg.exec([
        "-i",
        "test.mp4",
        "-t",
        `${duration}`,
        "-ss",
        `${start}`,
        "-r",
        "15",
        "out.mp4"
    ]);

    let data = await ffmpeg.readFile("out.mp4");
    let blob = new Blob([data.buffer], { type: "video/mp4" })
    previewUrl.value = URL.createObjectURL(blob);
    let name = uuidv4() + ".mp4";
    file.value = new File([blob], name);
    isClipping.value = false;
}

const genThumbnail = async () => {
    isGenThumbnail.value = true;
    let start = range.value[1].toString();

    await ffmpeg.exec([
        "-i",
        "test.mp4",
        "-ss",
        `${start}`,
        "-vframes",
        "1",
        "output.jpg"
    ]);

    let data = await ffmpeg.readFile("output.jpg");
    let blob = new Blob([data.buffer], { type: "image/jpg" });
    thumbnailUrl.value = URL.createObjectURL(blob);
    let name = uuidv4() + ".jpg";
    thumbnailFile.value = new File([blob], name);
    isGenThumbnail.value = false;
}

const handleChange = async (e) => {
    imgInput.value = e;
    const selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
        await ffmpeg.load();
        await ffmpeg.writeFile("test.mp4", await fetchFile(selected));

        fileError.value = null;
        videoUrl.value = URL.createObjectURL(selected);
    } else {
        file.value = null;
        fileError.value = "Please select an image file (png or jpg)";
    }
};

const deleteExercise = ref();
const selectedIndex = ref();

const handleDelete = (item, index) => {
    deleteExercise.value = item;
    selectedIndex.value = index;
    deleteDialog.value = true;
};

let editExercise = false;
let deleteFiles = {filePath: "", thumbnailPath : ""};
const handleEdit = (item) => {
    editExercise = true;
    newExercise.value = item;
    deleteFiles.filePath = newExercise.value.filePath;
    deleteFiles.thumbnailPath = newExercise.value.thumbnailPath;
    addExercise.value = true;
};

const confirmedDelete = async () => {
    await deleteStorage(deleteExercise.value.filePath);
    await deleteStorage(deleteExercise.value.thumbnailFilePath);
    await deleteDocument('exercises', user.value.uid, deleteExercise.value.id);
    items.value.splice(selectedIndex.value, 1);
    index--;
    deleteDialog.value = false;
    exerciseDialog.value = false;
};

const selectedExercise = ref();
const openExerciseDialog = (exercise) => {
    exerciseDialog.value = true;
    selectedExercise.value = exercise;
}

const items = ref([]);
let index = 0;
const load = ({ done }) => {
    for (let i = 0; i < 5; i++) {
        if (exercises.value && index < exercises.value.length) {
            items.value.push(exercises.value[index]);
            index++;
            done('ok');
        } else {
            done('empty');
        }
    }
}
</script>

<style></style>