<template>
    <v-layout class="justify-center">
        <v-sheet class="px-4 py-4 mx-2 rounded" elevation="1" width="20%">
            <form>
                <h3>Login</h3>
                <input type="email" placeholder="Email" v-model="email" @keyup.enter="handleSubmit">
                <input type="password" placeholder="Password" v-model="password" @keyup.enter="handleSubmit">
                <div v-if="error"  >{{ error }}</div>
                <v-btn v-if="!isPending" @click="handleSubmit">Log in</v-btn>
                <v-btn v-if="isPending">Loading</v-btn>
            </form>
        </v-sheet>
    </v-layout>
</template>

<script setup>
import router from '@/router';
import {ref} from 'vue';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '@/firebase/config';

const error = ref(null);
const isPending = ref(false);
const email = ref('');
const password = ref('');

const handleSubmit = async () => {
    isPending.value = true;
    error.value = null;

    try {
        await signInWithEmailAndPassword(auth, email.value, password.value)
        isPending.value = false;
    }
    catch(err) {
        error.value = 'Incorrect login credentials';
        isPending.value = false;
    }

    if (!error.value) {
        router.push({ name: 'TrainerDashboard' });
    }
};

</script>