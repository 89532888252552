import { ref } from 'vue';
import { db } from '../firebase/config';
import { collection, deleteDoc, documentId, getDocs, query, where } from 'firebase/firestore';

const useDocument = () => {
  const error = ref(null);
  const isPending = ref(false);

  // delete document
  const deleteDocument = async (collectionName, userId, id) => {
    const q = query(collection(db, 'app', userId, collectionName), where(documentId(), "==", id));
    const docSnap = await getDocs(q);
    isPending.value = true;
    error.value = null;

    try {
      await docSnap.forEach((doc) => {
        deleteDoc(doc.ref);
      });
      isPending.value = false;
    }
    catch(err) {
      console.log(err.message);
      isPending.value = false;
      error.value = 'could not delete the document';
    }
  }

  return { error, isPending, deleteDocument }
}

export default useDocument