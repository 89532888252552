<template>
  <VRow no-gutters>
    <VCol cols="2">
      <VCard>
        <h1>TWMS</h1>
        <VList>
            <VListItem v-for="(item, i) in navbarItems" :value="item" :to="{name: item.link}" :key="i">
              <VListItemTitle v-text="item.text"></VListItemTitle>
            </VListItem>
        </VList>
      </VCard>
    </VCol>
    <VCol cols="10">
      <VRow no-gutters>
        <VCol cols="10">
          <VSheet height="50">
          </VSheet>
        </VCol>
        <VCol cols="2">
          <VSheet height="50" class="py-2">
            <v-btn @click="handleClick">Logout</v-btn>
          </VSheet>
        </VCol>
      </VRow>
      <VRow no-gutters>
        <VCol cols="12" class="my-2"><slot/></VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth } from '@/firebase/config';
import { signOut } from 'firebase/auth';
import { VSheet } from 'vuetify/lib/components/index.mjs';

const error = ref(null);
const router = useRouter();
const navbarItems = ref([
  {link: "TrainerDashboard", text: "Dashboard"},
  {link: "Clients", text: "Clients"},
  {link: "Messages", text: "Messages"},
  {link: "Workout", text: "Workouts"},
  {link: "Exercise", text: "Exercises"},
  {link: "Calendar", text: "Calendar"}
]);

const handleClick = async () => {
  try {
    error.value = null;
    router.push({name: 'Login'});
    await signOut(auth);
  }
  catch(err) {
    error.value = err;
  }

  if (!error.value) {
    router.push({name: 'Login'});
  }
}

</script>

<style scoped>
</style>