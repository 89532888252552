<template>
    <VSheet class="px-2 mb-10" elevation="1">
        <nav>
            <img src="">
            <h1><router-link :to="{ name: 'TrainerDashboard'}">TWMS</router-link></h1>
            <div class="links">
                <div v-if="user">
                  <router-link :to="{name: 'Workout'}"><v-btn>Workouts</v-btn></router-link>
                  <router-link :to="{name: 'Exercise'}"><v-btn>Exercise Library</v-btn></router-link>
                  <v-btn @click="handleClick">Logout</v-btn>
                </div>
                <div v-else>
                  <router-link :to="{name: 'Signup'}"><v-btn>Sign up</v-btn></router-link>
                  <router-link :to="{name: 'Login'}"><v-btn>Log in</v-btn></router-link>
                </div>
            </div>
        </nav>
    </VSheet>
</template>

<script setup>
import { ref } from 'vue'
import getUser from '@/composables/getUser';
import { useRouter } from 'vue-router'
import { auth } from '@/firebase/config';
import { signOut } from 'firebase/auth';
import { VSheet } from 'vuetify/lib/components/index.mjs';

const error = ref(null);
const { user } = getUser();
const router = useRouter();

const handleClick = async () => {
  try {
    error.value = null;
    router.push({name: 'Login'});
    await signOut(auth);
  }
  catch(err) {
    error.value = err;
  }

  if (!error.value) {
    router.push({name: 'Login'});
  }
}

</script>

<style scoped>
  nav {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  nav img {
    max-height: 60px;
  }
  nav .links {
    margin-left: auto;
  }
  span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #eee;
  }
</style>