<template>
    <VBtn class="mb-2" @click="addClient = true">Invite New Client</VBtn>
    <VDialog v-model="addClient" max-width="600">
        <VSheet class="px-4 py-4 mx-2 my-2" elevation="1">
            <h4>Invite New Client</h4>
            <VTextField v-model="client.emailAddress" label="Email address"/>
            <VTextField v-model="client.firstName" label="First name"/>
            <VTextField v-model="client.lastName" label="Last name"/>
            <VBtn @click="inviteClients(client)" >Send invite</VBtn>
        </VSheet>
    </VDialog>
    <VSheet>
        <VExpansionPanels>
            <VExpansionPanel v-for="client in clientInvites">
                <VExpansionPanelTitle>
                    <VImg class="mx-2" style="border-radius: 50%;" maxWidth="50" aspectRatio="1" cover src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" ></VImg>
                    <div>
                        <h2>{{ `${client.firstName} ${client.lastName}`}}</h2>
                        <p>{{`Status: ${client.isPending ? "Pending" : "Subscribed"}`}}</p>
                    </div>
                </VExpansionPanelTitle>
                <VExpansionPanelText class="text-left">
                    <div class="my-2">
                        <VBtn color="red" text="Delete" @click="handleDelete(client)"/>
                    </div>
                </VExpansionPanelText>
            </VExpansionPanel>
            <VExpansionPanel v-for="client in clients">
                <VExpansionPanelTitle>
                    <VImg class="mx-2" style="border-radius: 50%;" maxWidth="50" aspectRatio="1" cover src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" ></VImg>
                    <div>
                        <h2>{{ `${client.firstName} ${client.lastName}`}}</h2>
                        <p>{{`Status: ${client.isPending ? "Pending" : "Subscribed"}`}}</p>
                    </div>
                </VExpansionPanelTitle>
                <VExpansionPanelText class="text-left">
                    <div class="my-2">
                        <VBtn color="red" text="Delete" @click="handleDelete(client)"/>
                    </div>
                </VExpansionPanelText>
            </VExpansionPanel>
        </VExpansionPanels>
    </VSheet>
    <VDialog v-model="confirmDelete">
        <VSheet class="py-4 px-4 mx-auto" width="400">
            <h2 class="my-2">Delete Client</h2>
            <p class="my-2">Are you sure you want to remove this client?</p>
            <v-btn text="Cancel" @click="confirmDelete = false;"></v-btn>
            <v-btn color="red" text="Delete" @click="confirmedDelete"></v-btn>
        </VSheet>
    </VDialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import getUser from '@/composables/getUser';
import { VTextField } from 'vuetify/lib/components/index.mjs';
import useCollection from '@/composables/useCollection';
import { timestamp } from '@/firebase/config';
import useDocument from '@/composables/useDocument';
const { user } = getUser();
const { error, addDocument, updateDocument, getDocuments } = useCollection();
const { deleteDocument } = useDocument();
const addClient = ref(false);
const client = ref({emailAddress: "", firstName: "", lastName: "", isPending: true, trainer: ""})

const clientInvites = ref(null);
var {getError} = getDocuments("client_invites", user.value.uid, clientInvites);

const clients = ref(null);
var {getError} = getDocuments("clients", user.value.uid, clients);

const trainer = ref(null);
var {getError} = getDocuments("users", user.value.uid, trainer);

const selectedClient = ref(null);
const confirmDelete = ref(false);

const inviteClients = async (newClient) => {
    newClient.trainer = user.value.displayName;
    newClient.trainerFirstName = trainer.value[0].firstName;
    newClient.trainerLastName = trainer.value[0].lastName;
    newClient.createdAt = timestamp;
    await addDocument("client_invites", user.value.uid, newClient)
    addClient.value = false;
    client.value = {emailAddress: "", firstName: "", lastName: "", isPending: true, trainer: ""};
};

const handleDelete = (item) => {
    selectedClient.value = item;
    confirmDelete.value = true;
};

const confirmedDelete = async () => {
    await deleteDocument('client_invites', user.value.uid, selectedClient.value.id);
    confirmDelete.value = false;
};
</script>

<style>

</style>