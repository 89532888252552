import { ref } from 'vue'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { auth } from '@/firebase/config';
import useCollection from '@/composables/useCollection';
import { timestamp } from '@/firebase/config';

const error = ref(null)
const isPending = ref(false);

const signup = async (email, password, displayName, firstName, lastName, isTrainer) => {
  error.value = null
  isPending.value = true;

  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    if (!res) {
      throw new Error('Could not complete signup')
    } else {
      const user = auth.currentUser;
      await updateProfile(user, { displayName: displayName });
      const { error, addDocument } = useCollection();
      addDocument(
        'users', user.uid,
        {
          userId: user.uid,
          userName: user.displayName,
          firstName: firstName,
          lastName: lastName,
          isTrainer: isTrainer,
          createdAt: timestamp
        })
    }

    error.value = null
    isPending.value = false;
    return res
  }
  catch (err) {
    console.log(err.message);
    error.value = err.message;
    isPending.value = false;
  }
}

const useSignup = () => {
  return { error, signup, isPending }
}

export default useSignup